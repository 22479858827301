<template>
  <div>
    <CRow>
      <CCol sm="12" :md="{size: 8, offset: 2}" style="height: 750px">
        <Chat
          :participants="participants"
          :myself="myself"
          :messages="messages"
          :chat-title="chatTitle"
          :placeholder="placeholder"
          :colors="colors"
          :border-style="borderStyle"
          :hide-close-button="hideCloseButton"
          :close-button-icon-size="closeButtonIconSize"
          :submit-icon-size="submitIconSize"
          :submit-image-icon-size="submitImageIconSize"
          :load-more-messages="(from + size < total) ? loadMoreMessages : null"
          :async-mode="asyncMode"
          :scroll-bottom="scrollBottom"
          :display-header="true"
          :send-images="false"
          :profile-picture-config="profilePictureConfig"
          :timestamp-config="timestampConfig"
          @onMessageSubmit="onMessageSubmit"
          @onType="onType"/>
      </CCol>
    </CRow>
    
   </div>
</template>

<script>
import { Chat } from 'vue-quick-chat'
import 'vue-quick-chat/dist/vue-quick-chat.css';
import AxiosBase from '../../api/AxiosBase'
import dayjs from 'dayjs';

export default {
  name: 'Conversation',
  components: {
    Chat
  },
  created() {
    this.loadMessage(0, 10);
  },
  data() {
    return {
      phoneNumber: this.$route.params.phone_number,
      total: 0,
      from: 0,
      size: 10,
      participants: [],
      myself: {},
      messages: [
      ],
      chatTitle: 'Conversation with ' + this.$route.params.phone_number,
      placeholder: 'SMS Content...',
      colors: {
        header: {
          bg: '#303C54',
          text: '#fff'
        },
        message: {
          myself: {
            bg: '#fff',
            text: '#000'
          },
          others: {
            bg: '#3498db',
            text: '#fff'
          },
          messagesDisplay: {
            bg: '#f7f3f3'
          }
        },
        submitIcon: '#b91010',
        submitImageIcon: '#b91010',
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      asyncMode: false,
      scrollBottom: {
        messageSent: true,
        messageReceived: true
      },
      hideCloseButton: true,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      submitImageIconSize: 20,
      displayHeader:true,
      profilePictureConfig: {
        others: false,
        myself: false,
        styles: {
          width: '30px',
          height: '30px',
          borderRadius: '50%'
        }
      },
      timestampConfig: {   
        format: 'yyyy-MM-dd HH:mm:ss',
        relative: false
      }
    }
  },
  methods: {
    loadMessage(from, size, resolve) {
      var _this = this;

      AxiosBase.get("/conversation/message/list_by_phonenumber", {
        params: {
          from: from,
          size: size,
          phone_number: _this.phoneNumber
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        let resCode = res?.data?.code;
        let resMsgs = res?.data?.value?.items;
        let total = res?.data?.value?.total;

        let msgIds = [];
        if (resCode != null && resCode >= 0 && resMsgs) {
          for (let msgItem of resMsgs) {
            msgIds.push(msgItem._id);

            let createdTime = dayjs.unix(msgItem.created_time / 1000);
            let createdTimeObj = {
              year: createdTime.get('year'),
              month: createdTime.get('month'),
              day: createdTime.get('date'),
              hour: createdTime.get('hour'),
              minute: createdTime.get('minute'),
              second: createdTime.get('second'),
              millisecond: 0,
            };
            let formattedMsg = {
              content: msgItem.message,
              myself: msgItem.system_message,
              participantId: msgItem.source,
              timestamp: createdTimeObj,
              type: 'text'
            };
            _this.messages.unshift(formattedMsg);

            if (msgItem.system_message) {
              _this.myself.name = msgItem.source + " (System SMS)";
              _this.myself.id = msgItem.source;
            } else {
              let canAddParticipant = true;
              for (let participant of _this.participants) {
                if (participant.id === msgItem.source) {
                  canAddParticipant = false;
                }
              }
              if (canAddParticipant) {
                _this.participants.push({
                  id: msgItem.source,
                  name: msgItem.source
                });
              }
            }
          }
          _this.total = total;
        } else {
          console.log(JSON.stringify(res));
        }

        if (resolve) {
          resolve([]);
        }

        _this.updateStateMsgToRead(msgIds);
      });
    },
    updateStateMsgToRead(msgIds) {
      AxiosBase.post("conversation/read_messages", msgIds)
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        if (resCode != null && resCode >= 0) {
          // do nothing
        } else {
          console.log(res);
        } 
      });
    },
    onType: function (event) {
      //here you can set any behavior
    },
    loadMoreMessages(resolve) {
      if (this.from + this.size < this.total) {
        this.from = this.from + this.size;
        this.loadMessage(this.from, this.size, resolve);
      }
    },
    onMessageSubmit: function (message) {
      var _this = this;

      AxiosBase.post("/conversation/send", {
        destination: _this.phoneNumber,
        message: message.content
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        let resCode = res?.data?.code;

        if (resCode != null && resCode >= 0) {
          _this.messages.push(message);
          console.log("Success");
        } else {
          console.log("Failed: " + JSON.stringify(res));
        }
      });
    }
  }
}
</script>
